<template>
  <div>
    <DueDate
      :task="task"
      :noArrow="noArrow"
      :fitContent="fitContent"
      :disabled="!hasEditPermission"
      @change:dueDate="changeDueDate"
      @delete:dueDate="deleteDueDate"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DueDate from "@/flow/views/components/commons/task/dueDate";

export default {
  components: { DueDate },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    noArrow: {
      type: Boolean,
      default: false
    },
    fitContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("flowTasks", ["hasEditPermission"])
  },
  methods: {
    ...mapActions("flowTasks", ["updateDueDate"]),
    changeDueDate(dueDateTimeMillis) {
      const { id: taskId } = this.task;
      this.updateDueDate({ taskId, dueDateTimeMillis });
    },
    deleteDueDate() {
      const { id: taskId } = this.task;
      this.updateDueDate({ taskId, dueDateTimeMillis: 0 });
    }
  }
};
</script>
